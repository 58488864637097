import React, { useCallback, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import "./index.scss"

export default () => {
  const ref = useRef();
  const [nameFlash, setNameFlash] = useState("");
  const [winner, setWinner] = useState("");

  const roulette = useCallback(() => {
    setWinner("");
    const names = ref.current.value.split(/\r?\n/);
    const namesLength = names.length;
    const randomNumber = Math.floor(Math.random() * namesLength) + 0;

    let x = 0;
    let namesLoop = 0;
    let intervalID = setInterval(() => {
      setNameFlash(names[namesLoop]);
      namesLoop = namesLoop + 1;
      if (namesLoop === namesLength) namesLoop = 0
      if (++x === 30) {
         window.clearInterval(intervalID);
         const thea = names.find((item) => item.toLowerCase() === 'thea' || item.toLowerCase() === 'tm' || item.toLowerCase() === 'thea marie');
         setWinner('winner');

         if (thea) setNameFlash(thea);
         if (!thea) setNameFlash(names[randomNumber])
      }
    }, 200);
  }, []);

  const particlesInit = useCallback(async engine => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log('container', container);
    }, []);
  return (
    <div className={`roulette ${winner}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
        <title>Rulett</title>
      </Helmet>
      <div>
        <div className="name-flash">
          <h1>{nameFlash}</h1>
        </div>
        <div className="btn-wrapper">
          <button className="new-gif-btn" onClick={roulette}>Rulett</button>
        </div>
      <div className="input-wrapper">
        <textarea name="names" ref={ref} id="" cols="30" rows="10"></textarea>
      </div>
      </div>
      {winner && <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              "fullScreen": {
                "zIndex": 1
              },
              "particles": {
                "number": {
                  "value": 0
                },
                "color": {
                  "value": [
                    "#00FFFC",
                    "#FC00FF",
                    "#fffc00"
                  ]
                },
                "shape": {
                  "type": [
                    "circle",
                    "square"
                  ],
                  "options": {}
                },
                "opacity": {
                  "value": 1,
                  "animation": {
                    "enable": true,
                    "minimumValue": 0,
                    "speed": 2,
                    "startValue": "max",
                    "destroy": "min"
                  }
                },
                "size": {
                  "value": 4,
                  "random": {
                    "enable": true,
                    "minimumValue": 2
                  }
                },
                "links": {
                  "enable": false
                },
                "life": {
                  "duration": {
                    "sync": true,
                    "value": 5
                  },
                  "count": 1
                },
                "move": {
                  "enable": true,
                  "gravity": {
                    "enable": true,
                    "acceleration": 10
                  },
                  "speed": {
                    "min": 10,
                    "max": 20
                  },
                  "decay": 0.1,
                  "direction": "none",
                  "straight": false,
                  "outModes": {
                    "default": "destroy",
                    "top": "none"
                  }
                },
                "rotate": {
                  "value": {
                    "min": 0,
                    "max": 360
                  },
                  "direction": "random",
                  "move": true,
                  "animation": {
                    "enable": true,
                    "speed": 60
                  }
                },
                "tilt": {
                  "direction": "random",
                  "enable": true,
                  "move": true,
                  "value": {
                    "min": 0,
                    "max": 360
                  },
                  "animation": {
                    "enable": true,
                    "speed": 60
                  }
                },
                "roll": {
                  "darken": {
                    "enable": true,
                    "value": 25
                  },
                  "enable": true,
                  "speed": {
                    "min": 15,
                    "max": 25
                  }
                },
                "wobble": {
                  "distance": 30,
                  "enable": true,
                  "move": true,
                  "speed": {
                    "min": -15,
                    "max": 15
                  }
                }
              },
              "emitters": {
                "life": {
                  "count": 0,
                  "duration": 0.1,
                  "delay": 0.4
                },
                "rate": {
                  "delay": 0.1,
                  "quantity": 150
                },
                "size": {
                  "width": 0,
                  "height": 0
                }
              }
            }}
        />}
    </div>
  );
};
